@import "../../styles/variables.scss";
@import "../../styles/animations.scss";

.topBar {
  display: flex;
  justify-content: space-between;

  align-items: center;
  padding: 0 20px;
  position: sticky;
  background-color: $blue;
  height: 55px;
  margin-bottom: 10px;
  z-index: 1000;
  box-shadow: 0px 2px 5px #aaa;

  .topBarLeft {
    float: left;
    transition: $transition;

    &:hover {
      transform: $hoverScaleSmall;
    }
    // &:hover img {
    //   animation: flash 0.5s ease infinite;
    // }
  }

  .topBarRight {
    float: right;
  }

  img {
    height: 30px;
  }
}
