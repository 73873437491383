@import "../../styles/variables.scss";

textarea {
  border: none;
  overflow: auto;
  outline: none;
  overflow: hidden;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/

  width: 100%;
  height: auto;
  background-color: transparent;

  font-size: 22px;
  margin-top: -1px;
  line-height: 35px;
  padding: 0 5px;
  word-spacing: 0.7px;
}
