@import "../../styles/variables.scss";
@import "../../styles/animations.scss";

button {
  background-color: #eee;
  color: #8a8a8a;
  padding: 2px 8px;
  border-radius: $borderRadius;

  transition: $transition;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  will-change: transform;

  &:hover {
    transform: $hoverScale;

    background-color: #e7e7e7;
  }
  &:active {
    background-color: #dbdbdb;
  }
}

.saveEnabled {
  color: #fff;
  background-color: rgb(126, 221, 131);
  &:hover {
    background-color: rgb(85, 200, 91);
  }
  &:active {
    background-color: rgb(70, 182, 75);
  }
}

.deleteButton {
  margin-left: 5px;
  &:hover {
    color: rgb(227, 76, 76);
  }
}

.addFlashNoteBtn {
  font-size: 18px;
}

.largeBtn {
  font-size: 24px;
  margin: 10px;
  color: $darkdefaultfont;
  background-color: #fff;

  &:hover {
    background-color: #fbfbfb;
  }

  // &:hover img {
  //   -webkit-animation: spin 0.35s ease;
  //   -moz-animation: spin 0.35s ease;
  //   animation: spin 0.35s ease;
  // }
}

.largeBtn img {
  height: 25px;
  transition: $transition;
  margin-right: 4px;
  margin-bottom: -4px;
}

.navBarBtn {
  font-size: 18px;
  color: $lightdefaultfont;
  background-color: unset;
  font-weight: 700;
  margin-left: 5px;
  &:hover {
    background-color: #4087da;
  }
}
.navBarBtnLogin {
  color: #a1a711;
  background-color: $yellow;
  &:hover {
    background-color: rgb(226, 233, 48);
  }
}

.navBarBtnLogout {
  svg {
    position: relative;
    top: 3px;
  }
}

.navBarBtnSelected {
  background-color: #4087da;
}

.submitBtn {
  font-size: 18px;
  padding: 8px 20px;
  color: $darkdefaultfont;
  background-color: rgb(236, 236, 236);
  font-weight: 700;
  margin-left: 5px;
}
