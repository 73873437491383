@import "./styles/variables.scss";
@import "./styles/mobileclauses.scss";

* {
  font-family: "rooney-sans", "Roboto", Helvetica, sans-serif !important;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

h1 {
  text-align: center;
  // padding-top: 3px;
}

h2 {
  color: #656565;
}

p,
span {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
input:focus,
select:focus,
textarea:focus,
div:focus,
button:focus {
  outline: none;
}

.hide {
  display: none !important;
}

.text-center {
  text-align: center;
}

.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}

.msgContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.msgSuccess {
  color: rgb(162, 209, 162) !important;
}
.msgError {
  color: rgb(214, 93, 93) !important;
}

.pageTitle {
  color: $darkdefaultfont;
  text-align: left;
}
