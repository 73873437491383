#mc_embed_signup {
  //   background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
  //   input[type="text"] {
  //     width: 100%;
  //     font-size: 20px;
  //     outline: none;
  //     padding: 4px 6px 6px 10px;
  //     border: 0;
  //     border-bottom: 2px solid rgb(224, 224, 224);
  //     background-color: transparent;
  //     // border-radius: 5px;
  //     position: relative;
  //     // box-shadow: 0px 0px 5px rgba(170, 170, 170, 0.313);
  //     &::placeholder {
  //       color: rgb(212, 212, 212);
  //     }
  //   }
}

/* MailChimp Form Embed Code - Slim - 12/15/2015 v10.7 */
#mc_embed_signup form {
  display: block;
  position: relative;
  text-align: left;
}
#mc_embed_signup h2 {
  font-weight: bold;
  padding: 0;
  margin: 15px 0;
  font-size: 1.4em;
}
#mc_embed_signup input {
  border: 1px solid #999;
  -webkit-appearance: none;
}
#mc_embed_signup input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
#mc_embed_signup input[type="radio"] {
  -webkit-appearance: radio;
}
#mc_embed_signup input:focus {
  border-color: #333;
}
#mc_embed_signup .button {
  clear: both;
  background-color: #aaa;
  border: 0 none;
  border-radius: 4px;
  transition: all 0.23s ease-in-out 0s;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin: 10px 5px 0px 0;
  padding: 0 22px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  justify-self: center;
  white-space: nowrap;
  width: fit-content;
  width: -moz-fit-content;
}
#mc_embed_signup .button:hover {
  background-color: #777;
}
#mc_embed_signup .small-meta {
  font-size: 11px;
}
#mc_embed_signup .nowrap {
  white-space: nowrap;
}
#mc_embed_signup .clear {
  clear: none;
  display: inline;
}
#mc_embed_signup .foot {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 97%;
}
@media screen and (max-width: 400px) {
  #mc_embed_signup .foot {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
  }
}

@media screen and (max-width: 400px) {
  #mc_embed_signup .referralBadge {
    width: 50%;
  }
}

#mc_embed_signup .brandingLogo {
  justify-self: right;
}
@media screen and (max-width: 400px) {
  #mc_embed_signup .brandingLogo {
    justify-self: left;
  }
}

#mc_embed_signup label {
  display: block;
  font-size: 16px;
  padding-bottom: 10px;
  font-weight: bold;
}
#mc_embed_signup input.email {
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, Verdana,
    sans-serif;
  font-size: 15px;
  display: block;
  padding: 0 0.4em;
  min-height: 32px;
  width: 94%;
  min-width: 130px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#mc_embed_signup div#mce-responses {
  float: left;
  top: -1.4em;
  padding: 0em 0.5em 0em 0.5em;
  overflow: hidden;
  width: 90%;
  margin: 0 5%;
  clear: both;
}
#mc_embed_signup div.response {
  margin: 1em 0;
  padding: 1em 0.5em 0.5em 0;
  font-weight: bold;
  float: left;
  top: -1.5em;
  z-index: 1;
  width: 80%;
}
#mc_embed_signup #mce-error-response {
  display: none;
}
#mc_embed_signup #mce-success-response {
  color: #529214;
  display: none;
}
#mc_embed_signup label.error {
  display: block;
  float: none;
  width: auto;
  margin-left: 1.05em;
  text-align: left;
  padding: 0.5em 0;
}
