@import "../../styles/variables.scss";

#helpText {
  margin: 1em 0 1em;
}

.instructions {
  text-align: center;
  color: #cfcfcf;
  font-size: 18px;
}
