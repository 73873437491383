@import "../../styles/variables.scss";

#app-container {
  padding: 3px 8px;
  border-radius: $borderRadius;
  background-color: #fff;
  box-shadow: $boxShadow;
  line-height: 1.5 !important;
  // display: flex;
}
