@media only screen and (max-width: 600px) {
  .wrapper {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .topBar {
    padding: 0 10px !important;
    height: 40px !important;

    img {
      height: 20px !important;
    }
    button {
      &[aria-label="Home"] {
        display: none;
      }
    }
  }
}
