@import "../../styles/variables.scss";

.colourGroupContainer {
  display: flex;
}

.colourGroupTextContainer {
  position: relative;
  font-size: 15px;
  text-align: center;
  font-weight: bolder;
  transition: $transition;
  opacity: 0.7;
}

.colourGroupTextContainer:hover {
  opacity: 1;
  cursor: pointer;
  transform: $hoverScale;
}

.text {
  color: #63636336;
  position: absolute;
  margin-left: 3px;
  margin-top: -21px;
}

.colourGroup {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  border: 2px solid #e3e3e3;
  box-shadow: 0px 0px 2px #bbbbbb;
  background-color: #c4c4c4;

  margin-right: 5px;
  box-sizing: initial;

  transition: $transition;
  opacity: 0.4;
}

.colourSelected {
  border-color: #176cee;
  box-shadow: 0px 0px 5px #0037ffd1;
  opacity: 1;
}

.selected {
  cursor: pointer;
  background-color: #8beed3;
  box-shadow: 0px 0px 4px #d0ffff;
  opacity: 0.4;
}
