@import "../../styles/variables";

body {
  background-color: $blue;
}
.feature-preview-all {
  h1.caption {
    font-size: 2.2em;
    color: rgb(158, 163, 22);
    text-align: center;
    padding-bottom: 15px;
  }
  background-color: $blue;
  padding-bottom: 200px;
}

.section-yellow {
  position: relative;
  margin-top: -40px;
  &:before {
    background-color: $yellow;
  }
}

.section-rotate-left {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: skewy(-3deg);
    transform-origin: 50% 0;
    outline: 1px solid transparent;
    backface-visibility: hidden;
  }
}

.section-rotate-right {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: skewy(3deg);
    transform-origin: 50% 0;
    outline: 1px solid transparent;
    backface-visibility: hidden;
  }
}

.section-top {
  h2 {
    color: #fff;
  }
  padding-top: 50px;
  padding-bottom: 80px;
}

.section-flashnote {
  padding-top: 55px;
  padding-bottom: 60px;
}

.section-text {
  padding-top: 50px;

  h1 {
    color: #fff;
  }
}

.resetButtonContainer {
  text-align: center;
  font-size: 1.3em;
  padding-top: 40px;
}
