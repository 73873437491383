@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0% {
    filter: drop-shadow(0px 0px 2px rgba(245, 253, 55, 0));
  }
  10% {
    filter: drop-shadow(0px 0px 2px rgb(245, 253, 55));
  }
  30% {
    filter: drop-shadow(0px 0px 2px rgba(245, 253, 55, 0.608));
  }
  40% {
    filter: drop-shadow(0px 0px 2px rgb(245, 253, 55));
  }
  50% {
    filter: drop-shadow(0px 0px 2px rgba(245, 253, 55, 0.299));
  }
  60% {
    filter: drop-shadow(0px 0px 2px rgba(245, 253, 55, 0));
  }
  70% {
    filter: drop-shadow(0px 0px 2px rgb(245, 253, 55));
  }
  80% {
    filter: drop-shadow(0px 0px 2px rgba(245, 253, 55, 0.608));
  }
  90% {
    filter: drop-shadow(0px 0px 2px rgba(245, 253, 55, 0.224));
  }
  100% {
    filter: drop-shadow(0px 0px 2px rgb(245, 253, 55));
  }
}
