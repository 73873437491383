.statusMsg {
  margin-left: 13px;
  color: #aaa;
  font-size: 12px;
  font-weight: 500;
}

.loaderHide {
  opacity: 0;
}
