@import "../../styles/variables.scss";

.flashNoteViewer {
  display: flex;
  justify-content: left;

  background-color: #fff;
  border-radius: $borderRadius;
  margin-top: 10px;
}

.flashNoteView {
  h1 {
    text-align: left;
    font-size: 1.5rem;
    width: 100%;
    margin-bottom: 10px;
  }
  margin: 5px 10px;
  display: flex;
  flex-wrap: wrap;
}

.noteViewFragment {
  padding: 0px 2px;
}

.hiddenFragment {
  color: transparent;
}
