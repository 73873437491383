@import "../../styles/variables.scss";

#text-field {
  margin-top: 10px;
  padding-bottom: 15px;
}

// #text-field > span {
//   border-radius: 3px;
//   padding: 2px;
//   font-size: 22px;
//   transition: $transition;
// }

// #text-field > span:hover {
//   cursor: pointer;
//   background-color: #8beed3;
//   box-shadow: 0px 0px 4px #d0ffff;
// }
