@import "../../styles/variables.scss";

.customOverlay {
  background: rgba(255, 255, 255, 0.9);
}
.customModal {
  color: #666;
  background: #ffffff;
  max-width: 350px;
  min-height: 100px;
  width: 100%;
  padding: 15px 5px;
  border-radius: $borderRadius;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);

  h1 {
    margin-bottom: 8px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modalBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 20px;
  }
  .modalCloseButton {
    padding: 4px 10px;
  }
}

.MuiFormControl-root {
  margin: 10px !important;
  width: 100%;
  max-width: 25ch;
}

.googleBtn {
  margin-top: 20px;
}

.orHr {
  margin-top: 30px;
  border: none;
  border-top: 1px solid #9a9a9a;
  color: #9a9a9a;
  overflow: visible;
  text-align: center;
  height: 5px;
  border-color: #9a9a9a;
  width: 100%;

  &::after {
    background: #ffffff;
    content: "or";
    padding: 0 4px;
    position: relative;
    top: -13px;
  }
}
