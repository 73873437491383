.collectionOptionsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 15px;
}

.collectionOptionsHeader {
  display: flex;
  flex-direction: column;
}

.collectionOptionsTitle {
  font-size: 24px;
}
