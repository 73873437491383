@import "../../styles/variables.scss";

$defaultColor: #dedede;
$infoColor: hsl(211, 100%, 95%);
$successColor: hsl(111, 60%, 91%);
$warningColor: hsl(54, 66%, 86%);
$errorColor: hsl(7, 100%, 95%);

@mixin pageMessage($color) {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  color: darken($color, 30%);
  margin: 10px;
  padding: 10px 20px;
  border-radius: $borderRadius;
  background-color: $color;
  border: 2px solid darken($color, 5%);
  box-shadow: $boxShadow;
  text-align: center;
  .pageMessageCloseButton {
    cursor: pointer;
    position: absolute;
    right: 2px;
    top: 2px;
    border-radius: 5px;
    height: 18px;
    width: 18px;
    background-color: $color;
    transition: $transition;
    &:hover {
      background-color: darken($color, 10%);
    }
    span {
      position: relative;
      top: -4px;
      &:hover {
        color: darken($color, 35%);
      }
    }
  }
}

.pageMessageDefault {
  @include pageMessage($defaultColor);
}
.pageMessageInfo {
  @include pageMessage($infoColor);
}
.pageMessageSuccess {
  @include pageMessage($successColor);
}
.pageMessageError {
  @include pageMessage($errorColor);
}
.pageMessageWarning {
  @include pageMessage($warningColor);
}
