@import "../../styles/variables.scss";

.flashNote {
  outline: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  font-size: 22px;
  background-color: rgb(249, 249, 249);
  border: 2px solid transparent; // 5px
  border-radius: $borderRadius; // 10px
  padding: 4px 4px 4px 4px;
  margin: 8px 5px 20px 5px;
}

.titleBar {
  display: flex;
  align-items: center;
}

.noteTitle {
  font-size: 16px;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.titleText {
  background-color: transparent;
  outline: none;

  font-weight: 700;
  color: rgb(101, 101, 101);
  margin: 0px 10px 2px 12px;
  transition: $transition;
  border: none;
}

.titleText:hover,
.titleText:active,
.titleText:focus {
  color: rgb(101, 101, 101);
  cursor: text;
}

.titleText::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(210, 210, 210);
  opacity: 1; /* Firefox */
}

.titleText:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(210, 210, 210);
}

.titleText::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(210, 210, 210);
}

.editingNote {
  border-color: rgb(229, 229, 229);
}
