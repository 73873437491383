@import "../../styles/variables.scss";

.collectionList {
  display: flex;
  flex-direction: column;
  .loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.collectionContainer {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  padding: 8px 10px;
  margin: 4px 10px;
  border-radius: $borderRadius;
  position: relative;
  border: 2px solid transparent;

  transition: $transitionFast;

  .icon {
    margin-right: 5px;
    position: relative;
    bottom: -2px;
  }

  &:hover {
    cursor: pointer;
    background-color: #fbfbfb;
    border-color: rgba(179, 228, 255, 0.313);
  }
}
