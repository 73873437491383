@import "../../styles/variables.scss";

.noteFrag {
  position: relative;
  padding: 0px 2.5px;
  border-radius: $borderRadius / 2;
  margin-bottom: 2px;
  transition: $transitionFast;
}

.noteFragHighlightMode:hover {
  cursor: pointer;
  // transform: scale(1.05);
}

// .noteFrag:hover {
//   cursor: pointer;
//   transform: scale(1.05);
// }

.noteFragM {
  padding: 0px 2.5px;

  border-radius: $borderRadius / 2;
  margin-bottom: 2px;
  transition: $transitionFast;
}

.hint {
  opacity: 0.6;
  font-size: 18px;
  padding: 6px 2.5px 0px 2.5px;
  margin-bottom: 2px;
  box-shadow: 0 -3px 0px inset turquoise;
}
.hintLabel {
  &:before {
    content: "hint: ";
    opacity: 0.6;
    font-size: 11px;
    position: absolute;
    margin-top: -8px;
  }
}

.addHintContainer {
  height: 0;
  width: 0;
  position: relative;
}
.addHintButton {
  // height: 20px;
  // width: 40px;
  // padding: 0 4px;
  font-size: 14px;
  position: absolute;
  left: -5px;
  top: -15px;
  box-shadow: 2px 3px 3px rgba(107, 107, 107, 0.253);
  z-index: 10;
  // transition: $transition;
  // &:hover {
  //   background-color: rgb(192, 192, 192);
  //   transform: scale(1.05);
  // }
}

.addHintInputContainer {
  position: absolute;
  z-index: 8;
  left: -5px;
  top: -6px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .hintClose {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    top: -5px;
    right: -5px;
    z-index: 10;
    background-color: rgb(224, 224, 224);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    transition: $transitionFast;

    &:hover {
      background-color: #aaa;
    }
  }

  input {
    outline: none;
    padding: 2px 4px 4px 4px;
    border: 2px solid rgb(224, 224, 224);
    background-color: #fff;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 0px 5px rgba(170, 170, 170, 0.313);
  }

  .statusMsg {
    // text-shadow: 0px 0px 5px rgba(170, 170, 170, 0.211);
    background-color: #eee;
    border-radius: 0px 0px 5px 5px;
    padding: 4px 4px 0px 4px;
    margin-top: -6px;
  }
}

.wordSelected {
  cursor: pointer;
}

.ws2 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ws3 {
  border-radius: 0;
}
.ws4 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cg0 {
  background-color: $wordHighlight;
}

.cg1 {
  background-color: $cg1;
}
.cg2 {
  background-color: $cg2;
}
.cg3 {
  background-color: $cg3;
}
.cg4 {
  background-color: $cg4;
}
.cg5 {
  background-color: $cg5;
}
.cg6 {
  background-color: $cg6;
}
.cg7 {
  background-color: $cg7;
}
.cg8 {
  background-color: $cg8;
}
.cg9 {
  background-color: $cg9;
}
.cg10 {
  background-color: $cg10;
}
.cg11 {
  background-color: $cg11;
}
.cg12 {
  background-color: $cg12;
}
.cg13 {
  background-color: $cg13;
}
.cg14 {
  background-color: $cg14;
}
.cg15 {
  background-color: $cg15;
}

.hcg0:hover {
  background-color: $wordHighlight;
}
.hcg1:hover {
  background-color: $cg1;
}
.hcg2:hover {
  background-color: $cg2;
}
.hcg3:hover {
  background-color: $cg3;
}
.hcg4:hover {
  background-color: $cg4;
}
.hcg5:hover {
  background-color: $cg5;
}
.hcg6:hover {
  background-color: $cg6;
}
.hcg7:hover {
  background-color: $cg7;
}
.hcg8:hover {
  background-color: $cg8;
}
.hcg9:hover {
  background-color: $cg9;
}
.hcg10:hover {
  background-color: $cg10;
}
.hcg11:hover {
  background-color: $cg11;
}
.hcg12:hover {
  background-color: $cg12;
}
.hcg13:hover {
  background-color: $cg13;
}
.hcg14:hover {
  background-color: $cg14;
}
.hcg15:hover {
  background-color: $cg15;
}
