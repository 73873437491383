// $colours: (
//   default: (
//     blue: #469cff,
//     pageBackground: #eee,
//   ),
//   dark: (
//     $blue: rgb(248, 0, 0),
//     pageBackground: #eee,
//   ),
// );

$blue: #469cff;
$yellow: rgb(245, 253, 55);
$ogHighlight: #8beed3;
$wordHighlight: $yellow; //
$transition: 0.2s ease;
$transitionFast: 0.01s;
$borderRadius: 8px;
$boxShadow: 0px 0px 20px #dedede;
$darkdefaultfont: #656565;
$lightdefaultfont: #f6faff;
$hoverScale: scale(1.05) translate3d(0, 0, 0) perspective(1px);
$hoverScaleSmall: scale(1.02) translate3d(0, 0, 0) perspective(1px);

$cg0: $wordHighlight;
$cg1: hsl(310, 100%, 85%);
$cg2: hsl(135, 100%, 85%);
$cg3: hsl(200, 100%, 85%);
$cg4: hsl(85, 100%, 85%);
$cg5: hsl(24, 100%, 85%);
$cg6: hsl(256, 100%, 85%);
$cg7: hsl(352, 100%, 85%);
$cg8: hsl(215, 100%, 85%);
$cg9: hsl(359, 100%, 85%);
$cg10: hsl(294, 100%, 85%);
$cg11: hsl(18, 100%, 85%);
$cg12: hsl(222, 100%, 85%);
$cg13: hsl(293, 100%, 85%);
$cg14: hsl(341, 100%, 85%);
$cg15: hsl(261, 100%, 85%);
