@import "../../styles/variables";
.social-details-item {
  width: 100%;
  height: 100px;
}
.social-details-content {
  padding: 5px 8px;
  background-color: rgb(247, 247, 247);
  border-radius: 0px 0px 5px 5px;
}

.social-details-item-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  border-radius: 5px 5px 0px 0px;
  span {
    margin-left: 5px;
  }
  &.youtube {
    background-color: #ff2600;
  }
  &.instagram {
    background: rgb(255, 204, 82);
    background: linear-gradient(
      28deg,
      rgba(255, 204, 82, 1) 0%,
      rgba(241, 77, 89, 1) 50%,
      rgba(70, 100, 201, 1) 100%
    );
  }

  &.mailing-list {
    background-color: rgb(129, 108, 224);
  }

  transition: $transition;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  will-change: transform;
  &:hover {
    border-radius: 5px;
    transform: scale(1.05) translate3d(0, 0, 0) perspective(1px);
  }
}

.social-details-link {
  text-decoration: none;
}
